@media (max-width: 500px) {
	.tasks-container {
		max-width: 350px;
	}
}

.tasks-container {
	min-width: 300px;
	max-width: 500px;
	margin: 0 5px 0 5px;
}

.tasks-deliverable-box {
	min-width: 300px;
	text-align: left;
	font-weight: bold;
}

.tasks-call-description {
	text-align: left;
	margin: auto;
	border: 1px solid black;
	padding: 10px;
}

.tasks-call-details {
	width: 350px;
	text-align: left;
	margin: auto;
}

.tasks-call-form {
	width: 350px;
	text-align: left;
	margin: auto;
}

.btn-fade {
	opacity: 0.6;
}

.task-btn {
	white-space: break-spaces;
	line-height: 1.2;
}