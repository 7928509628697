html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

body {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.app {
  text-align: center;
  margin: 0 auto;
}

.app-animate {
  animation: slidefade 3s ease-in;
}

@keyframes slidefade {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.img-animate {
  animation: movefade 3s ease;
}

@keyframes movefade {
  0% {
    padding-top: 30vh;
  }

  40% {
    padding-top: 30vh;
  }

  70% {
    padding-top: 0px;
  }
}

.nav-container {
  min-width: 300px;
  margin: 0 5px 0 5px;
}

.nav-container a,
.nav-container button {
  width: 240px;
  margin: auto;
}

.spinner {
  margin: auto;
  display: flex;
}

.btn-pals-blue {
  background-color: #0076c0;
  border-color: #0076c0;
  color: white;
}
.btn-pals-blue:hover {
  background-color: #005e99;
  border-color: #005e99;
  color: white;
}

.btn-pals-green {
  background-color: #7dbf4b;
  border-color: #7dbf4b;
  color: white;
}
.btn-pals-green:hover {
  background-color: #6baa3c;
  border-color: #6baa3c;
  color: white;
}

.btn-pals-orange {
  background-color: #fbb040;
  border-color: #fbb040;
  color: white;
}
.btn-pals-orange:hover {
  background-color: #fa9805;
  border-color: #fa9805;
  color: white;
}

.btn-pals-teal {
  background-color: #51c8e8;
  border-color: #51c8e8;
  color: white;
}
.btn-pals-teal:hover {
  background-color: #1aa5cb;
  border-color: #1aa5cb;
  color: white;
}
