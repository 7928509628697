.report-container {
	min-width: 300px;
	max-width: 600px;
	margin: 0 5px 0 5px;
}

.report-container button,
.report-container a {
	width: 300px;
	margin: auto;
}

.report-form {
	text-align: left;
}